/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/inter/inter.css'
@import "primeng/resources/themes/lara-light-blue/theme.css"
@import "primeng/resources/primeng.css"

@import 'flag-icons/sass/flag-icons.scss'

@tailwind base
@tailwind components
@tailwind utilities

html, body
  height: 100%
  width: 100%

*::before
  box-sizing: border-boxtop-border-primary

*::after
  box-sizing: border-box

*
  margin: 0
  padding: 0
  box-sizing: border-box

ul[role="list"]
  list-style: none

ol[role="list"]
  list-style: none

// Input type = number
input[type=number]
  -moz-appearance: textfield
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

.application-logo
  max-height: 34px
  width: 147px
  object-fit: contain

.bg-white-new
  background: rgb(243 244 246)

.profile-image-class
  height: 80px
  width: 80px
  border-radius: 50%
  object-fit: cover

.loading-gif-large
  height: 75px
  width: 75px
  border-radius: 50%
  object-fit: cover

.svg-icon-class-Xxsmall
  height: 10px
.svg-icon-class-Xsmall
  height: 14px
.svg-icon-class-small
  height: 18px
.svg-icon-small
  height: 23px
.svg-icon-class
  height: 24px
.svg-icon-class-md
  height: 28px
.svg-icon-class-rank
  height: 18px
.svg-icon-class-bidder
  height: 22px

.floating-button
  position: absolute
  bottom: 24px
  right: 24px
.overflow-class-xsmall
  overflow: hidden
  text-overflow: ellipsis
  max-width: 100px
  white-space: nowrap

.overflow-class-small
  overflow: hidden
  text-overflow: ellipsis
  max-width: 230px
  white-space: nowrap
.overflow-class-large
  overflow: hidden
  text-overflow: ellipsis
  max-width: 600px
  white-space: nowrap
.img-portrait
  object-fit: contain !important
  // background: $clr-grey-700

.small-image-sadmin
  max-height: 30px
  object-fit: contain
  width: 100%
  height: 100%
  max-width: 90px

.tr-xs-icon
  height: 12px
  display: inline-flex

// input, select
//   color: rgb(156 163 175) !important
input:focus, select:focus
    border: 1px solid rgb(156 163 175)
    outline: none 
    border-radius: 6px
p-autocomplete
  .p-inputwrapper-focus:focus
    border: none !important

.is-invalid
  border: 1px solid rgb(248 113 113) !important
  background-color: rgb(254 226 226) !important

.p-dialog .p-dialog-content
  padding: 0rem !important
  // overflow-y: hidden !important

.p-autocomplete.p-component.p-inputwrapper.p-inputwrapper-filled.p-focus.p-inputwrapper-focus 
  display: grid !important
  // height: 30px !important 
  padding: 0px !important
  outline: none !important
  width: 100%
  margin-top: 3px !important


.p-datatable .p-datatable-thead > tr > th
    padding: 8px !important
    color: #475569 !important
    font-size: 12px
    background-color: rgb(248 250 252)
    font-weight: 600
    border-bottom: 1px solid #D1D5DB

.p-datatable .p-element.p-datatable-tbody > tr > td
    padding: 8px !important
    font-size: 12px
    color: #475569 !important
    font-weight: 440
    border-right: 1px solid #D1D5DB
    
.p-datatable .p-element.p-datatable-tbody > tr
    border-bottom: 2px solid #D1D5DB !important
.p-datatable .p-element.p-datatable-tbody > tr:hover
    background: rgb(248 250 252)


.p-icon.p-sortable-column-icon
  height: 12px !important
  width: 12px !important
  display: flex !important
  align-items: center !important
  margin-top: 3px !important

.p-inputtext
  font-size: 14px
  font-weight: 500 !important
  color: #4a5568
  background: transparent
.p-inputtext.p-inputwrapper-focus
  font-size: 14px
  font-weight: 500 !important
  border: none
  outline: none !important

.form-select
  font-size: 14px !important
  font-weight: 500 !important

.p-autocomplete.p-component.p-inputwrapper.p-inputwrapper-filled
  width: 100%
  height:100% 

textarea:focus-visible
  outline: none !important


.p-dialog .p-dialog-header
  padding: 8px 16px !important
.p-dialog .p-dialog-content
  border-radius: 8px
.p-dialog-footer
  padding: 12px

p-confirmDialog .p-dialog-content 
  border-radius: 0px !important

.tr-vertical-text
  transform: rotate(-180deg)
  color: rgba(255, 255, 255, 0.70)
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 16px /* 114.286% */
  letter-spacing: 0.14px
  writing-mode: vertical-lr
  text-orientation: mixed


select option
  background-color: white
  color: #4a5568

  &:checked
    background-color: #007bff
    color: white !important

  &:hover
    background-color: #f1f1f1
    color: black

  &:disabled
    color: #9ca3af

.p-menuitem-content
  background: white

.p-icon.p-sortable-column-icon
  height: 12px !important
  width: 12px !important
  display: flex !important
  align-items: center !important
  margin-top: 3px !important

.disable-table-row
  background: #f0f0f0
  opacity: .4


.no-data-td
  width: 80px
  height: 8px
  background: #E5E7EB
  border-radius: 100px

.header-image-border
  padding: 6px
  background: url(/assets/icons/tr-profile-border.svg)
  background-position: center
  background-size: cover
  
.header-image
  width: 32px !important
  height: 32px !important
  border-radius: 50%
  margin-top: 0px
  margin-bottom: 0px !important
  border: 1px solid linear-gradient(90deg, rgba(55,67,240,1) 0%, rgba(102,55,240,1) 35%, rgba(240,55,159,1) 100%)

.p-datatable-wrapper
  border-top-right-radius: 8px
  border-top-left-radius: 8px
.header-stages
  border-top-right-radius: 0.375rem
  border-top-left-radius: 0.375rem


.fixed-footer
  position: fixed
  left: 20%
  right: 20%
  bottom: 5%

.p-confirm-dialog-message
  padding: 16px !important


.p-chips-multiple-container
  width: 100% !important

.p-chips-token
  padding: 0px 6px 0px 6px

.p-component-overlay
  background-color: rgba(0,0,0,.86) !important
.border-chip
  border: none !important

::ng-deep .p-overlaypanel-content
  padding: 8px !important

.overflow-class-small-sidebar
  overflow: hidden
  text-overflow: ellipsis
  width: 50px
  white-space: nowrap
.overflow-class-plants-badge
  overflow: hidden
  text-overflow: ellipsis
  width: 40px
  white-space: nowrap
.overflow-class-org
  overflow: hidden
  text-overflow: ellipsis
  width: 134px
  white-space: nowrap
.footer
  position: absolute
  width: 100%
  bottom: 0
  background: white
  display: flex
  align-items: flex-end
  justify-content: flex-end
  align-content: stretch

.drawer-header
  position: sticky
  width: 100%
  top: 0
  background: white
  display: flex
  align-items: flex-end
  justify-content: flex-end
  align-content: stretch


.p-dialog-footer
  padding: 12px

.p-picklist-filter-icon 
    position: absolute
    top: 35% !important
.p-icon.p-multiselect-filter-icon
  margin-top: -8px !important
.p-picklist.p-picklist-filter-container.p-picklist-filter-input 
    padding: 10px !important
.p-input-icon-left >.p-inputtext 
    padding-left: 2.5rem !important
    padding-top: 10px !important
    padding-bottom: 10px !important
    width: 100%
.p-input-icon-left.p-input-icon-right 
    position: relative
    display: block !important
.p-multiselect 
  display: flex !important
  box-shadow: none

ngb-datepicker.dropdown-menu
  background: #f0f1f3
.p-checkbox-box 
    width: 16px
    height: 16px
    border: 1px solid gray
    display: flex
    align-items: center
    margin-top: 3px


.tr-overflow-y-drawer

  max-height: calc(100% - 8rem)
  overflow: hidden
  overflow-y: auto

.custom-tooltip
  display: flex !important
  align-items: center
  padding: 8px !important
  background-color: transparent
  color: white
  font-size: 12px !important
  border-radius: 8px
.p-multiselect-item
  font-size: 12px
.p-multiselect-label
  font-size: 14px
.p-menu-overlay
  width: 200px !important
  margin-top: 24px 
  left: auto !important
  right: 30px
  border-radius: 8px !important
  background: white !important
  border: 1px solid cbd5e1
  overflow: hidden
  padding-top: 8px
  padding-bottom: 8px



.p-chips-token-label
  padding-right: 4px !important

p-toast
  .p-toast
    width: 20rem
    margin-top: 2rem

.p-toast-icon-close
  display: none


// .p-toast-message-text 
//   padding-left: 8px
// // .p-toast-message-content
// //   padding: 12px

p-button
  .p-button-loading-icon
    margin-left: 8px
    margin-right: 8px
.p-dropdown-trigger-icon
  width: 10px 
  height: 10px
  color: #000
.p-dropdown-trigger
  width: 12px 
.dropdown-classes.p-dropdown-trigger
  width: 24px 
.p-multiselect-trigger
  width: 18px 
.p-icon.p-multiselect-trigger-icon
  width: 10px
  height: 10px
  color: #000
p-dropdown
  .p-dropdown-clear-icon.p-icon
    width: 12px
    height: 12px
    position: absolute
    top: 9px
    right: 20px
.p-dropdown-item
  font-size: 14px
  font-weight: 400
  color: #4a5568
.p-dropdown-panel
    padding: 8px
    margin-top: 8px
p-celleditor
  font-size: 14px
  color: #4a5568
.disabled 
  color: black 
  pointer-events: none
  opacity: 1
  cursor: not-allowed
.p-inputtext:enabled:focus
  box-shadow: none !important

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext 
    box-shadow: none !important
    border: none
.p-dropdown 
  box-shadow: none !important
  outline: none !important
  height: 28px
p-autocomplete
  .p-inputtext:enabled:focus
    border: none !important
p-multiSelect
  .p-inputtext:enabled:focus
    box-shadow: none !important
    border: none
p-inputnumber
  .p-inputtext:enabled:focus
    border: none !important
    width: 100%
.p-inputnumber
  width: 100%
  padding-left: 0px
  padding-right: 0px
p-calendar
    width: 100%
.p-calendar
  width: 100%
    
p-paginator
  .p-dropdown-label
    padding-right: 4px

p-button :enabled:focus
  box-shadow: none !important

.p-multiselect-items-wrapper
  padding: 8px


* ::before, ::after 
    border-style: none !important


.p-multiselect-header
    border: 1px solid rgb(212 212 212 / var(--tw-bg-opacity, 1))
    padding: 2px
    margin: 8px
    border-radius: 8px
    background: white


.p-autocomplete-item
  padding: 8px !important
  font-size: 14px !important
// .p-autocomplete-items
//   padding: 8px !important
.p-multiselect-panel
  top: 10px
  position: absolute

// input:-webkit-autofill 
//   background-color: transparent !important
//   transition: background-color 5000s ease-in-out 0s
// input:-moz-placeholder 
//   background-color: transparent !important
// input:-ms-input-placeholder 
//   background-color: transparent !important


.p-multiselect-filter:focus
  border: none !important
  width: 100%
  padding-left: 4px